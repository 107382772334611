import React from 'react';
import Summary from '../components/Summary';
import Details from '../components/Details';

const Home = (props) => {

    const { dealsData } = props;

    return (
        <div className="App px-24 border border-gray-300 rounded app-margin">
            <Summary dealsData={dealsData} />
            <Details dealsData={dealsData} />
        </div>
    );
};

export default Home;